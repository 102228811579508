body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.form-header,
.form-container {
  text-align: center;
}

.form-container input {
  width: 300px;
}

.form-container .MuiTextField-root {
  margin-bottom: 16px;
}

.clearfix {
  overflow: auto;
}
.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

button.submit-form {
  margin: 24px 0;
}

.loader {
  margin: 0 45%;
}
.title-text {
  flex-grow: 1;
}

button.logout-button {
  border: 1px solid #fff;
  padding: 5px 15px;
  color: #fff;
}

.loader-msg {
  font-size: 16px;
  font-weight: bold;
}

.csv-download {
  visibility: hidden;
}

.roomNo {
  text-align: center;
}

.roomNo > #block {
  width: 100px;
}

.capital {
  text-transform: uppercase;
}

.center {
  text-align: center;
}

.searchbox input {
  padding: 8px;
}
.searchTable {
  margin-top: 24px;
}
